import React from 'react'
import { SITES_ROUTES } from '../../constants'
import { useAuth } from '@src/modules/Auth/shared/state'
import { ChartPieIcon } from '@heroicons/react/24/solid'
import { FaInfo, FaTasks } from 'react-icons/fa'
import { GrDocumentText } from 'react-icons/gr'
import { PiWarningDiamond } from 'react-icons/pi'
import { generatePath, NavLink } from 'react-router-dom'
import { ESCALATIONS_ROUTES } from '@src/modules/Escalations/constants'
import { TASKS_ROUTES } from '@src/modules/Tasks/constants'
import { useUnresolvedEscOccurrenceCount } from '@src/modules/Escalations/hooks/useUnresolvedEscOccurrenceCount'
import { Badge } from '@therms/atalaya'
import { REPORTS_ROUTES } from '@src/modules/Reports/constants'

function CurrentSiteMenuLinks() {
  const { currentSite } = useAuth()
  const count = useUnresolvedEscOccurrenceCount()

  if (currentSite) {
    return (
      <>
        <div className="flex-col flex justify-between pt-sm">
          <div className="text-md font-semibold leading-6 text-gray-200 truncate">
            {currentSite.name}
          </div>

          <div className="text-xs font-light text-gray-300 truncate">
            {currentSite.address}
          </div>
        </div>
        <ul className="-mx-2 mt-2 space-y-1">
          <li>
            <NavLink
              activeClassName="bg-gray-900 text-white"
              className="group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold text-gray-200 hover:text-white hover:bg-gray-900"
              to={generatePath(SITES_ROUTES.VIEW_SITE, {
                siteId: currentSite.id,
              })}
            >
              <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-400 bg-gray-500 font-medium text-white">
                <FaInfo />
              </span>

              <span className="truncate">Info</span>
            </NavLink>

            <NavLink
              activeClassName="bg-gray-900 text-white"
              className="group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold text-gray-200 hover:text-white hover:bg-gray-900"
              to={ESCALATIONS_ROUTES.__ROOT__}
            >
              <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-400 bg-gray-500 font-medium text-white">
                <PiWarningDiamond />
              </span>
              <div className="w-full flex justify-between">
                <span className="truncate">Escalations</span>
                {!!count && <Badge variant="main" value={count} />}
              </div>
            </NavLink>

            <NavLink
              activeClassName="bg-gray-900 text-white"
              className="group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold text-gray-200 hover:text-white hover:bg-gray-900"
              to={REPORTS_ROUTES.__ROOT__}
            >
              <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-400 bg-gray-500 font-medium text-white">
                <GrDocumentText />
              </span>
              <span className="truncate">Reports</span>
            </NavLink>

            <NavLink
              activeClassName="bg-gray-900 text-white"
              className="group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold text-gray-200 hover:text-white hover:bg-gray-900"
              to={TASKS_ROUTES.__ROOT__}
            >
              <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-400 bg-gray-500 font-medium text-white">
                <FaTasks />
              </span>
              <span className="truncate">Tasks</span>
            </NavLink>

            <NavLink
              activeClassName="bg-gray-900 text-white"
              className="group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold text-gray-200 hover:text-white hover:bg-gray-900"
              to={generatePath(SITES_ROUTES.VIEW_SITE_STATS, {
                siteId: currentSite.id,
              })}
            >
              <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-400 bg-gray-500 font-medium text-white">
                <ChartPieIcon />
              </span>
              <span className="truncate">Stats</span>
            </NavLink>
          </li>
        </ul>
      </>
    )
  }

  return null
}
export { CurrentSiteMenuLinks }
