import { Tabs } from '@therms/atalaya'
import React from 'react'
import { useUrlState } from '@src/hooks/useUrlState'
import { ReportTypesTable } from '@src/modules/Reports/router/screens/Index/components/ReportTypesTable'
import { CustomReportCompletionsTable } from '@src/modules/Reports/router/screens/Index/components/CustomReportCompletionsTable'

function ReportsIndex() {
  const [tab, setTab] = useUrlState('reportstabs', 'types')

  return (
    <div>
      <div className="text-lg">Site Reports</div>

      <Tabs currentKey={tab} initialTabKey={tab} onTabClick={setTab}>
        <Tabs.Tab tabKey="types" title="Types">
          <ReportTypesTable />
        </Tabs.Tab>

        <Tabs.Tab tabKey="completed" title="Completed">
          <CustomReportCompletionsTable />
        </Tabs.Tab>
      </Tabs>
    </div>
  )
}

export { ReportsIndex }
