import React, { useCallback } from 'react'
import {
  matchPath,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import { BreadcrumbsContextProvider } from './modules/Layout/shared/Breadcrumbs/BreadcrumbsContextProvider'
import { AppLayoutContainer } from './modules/Layout/shared/AppLayoutContainer'
import { OrganizationRoutes } from './modules/Organization/router/Routes'
import { SitesRoutes } from './modules/Sites/router/Routes'
import { UsersRoutes } from './modules/Users/router/Routes'
import { ORGANIZATION_ROUTES } from '@src/modules/Organization/constants'
import { SITES_ROUTES } from './modules/Sites/constants'
import { USERS_ROUTES } from '@src/modules/Users/constants'
import { PositionsRoutes } from './modules/Positions/router/Routes'
import { POSITIONS_ROUTES } from '@src/modules/Positions/constants'
import { ContainerDimensionsContextProvider } from './contexts/ContainerDimensionsContext'
import { TASKS_ROUTES } from '@src/modules/Tasks/constants'
import { TasksRoutes } from '@src/modules/Tasks/router/Routes'
import { ContactsRoutes } from '@src/modules/Contacts/router/Routes'
import { CONTACTS_ROUTES } from '@src/modules/Contacts/constants'
import { ESCALATIONS_ROUTES } from '@src/modules/Escalations/constants'
import { EscalationsRoutes } from '@src/modules/Escalations/router/Routes'
import * as Sentry from '@sentry/react'
import { EMAIL_PREFERENCES_ROUTES } from '@src/modules/Emails/constants'
import { EmailPreferencesRoutes } from '@src/modules/Emails/Routes'
import {REPORTS_ROUTES} from "@src/modules/Reports/constants";
import {ReportsRoutes} from "@src/modules/Reports/router/Routes";

function AppRouter() {
  const history = useHistory()
  const { pathname } = useLocation()
  const linkMatcher = useCallback(
    (link) => !!matchPath(pathname, { path: link, exact: true }),
    [pathname],
  )

  return (
    <ErrorBoundary
      onError={(error, componentStack) => {
        Sentry.withScope((scope) => {
          scope.setExtras(componentStack)
          Sentry.captureException(error)
        })
      }}
      FallbackComponent={({ error, resetErrorBoundary }) => (
        <div className="text-color-critical">
          {error.message}
          <div>
            <button onClick={resetErrorBoundary} type="button">
              reset
            </button>
          </div>
        </div>
      )}
      onReset={() => {
        window.location.reload()
      }}
    >
      <ContainerDimensionsContextProvider>
        <BreadcrumbsContextProvider
          isCurrentLinkMatcher={linkMatcher}
          onBack={history.goBack}
          onNavigate={history.push}
        >
          <AppLayoutContainer>
            <Switch>
              <Route
                path={ORGANIZATION_ROUTES.__ROOT__}
                component={OrganizationRoutes}
              />

              <Route
                path={CONTACTS_ROUTES.__ROOT__}
                component={ContactsRoutes}
              />

              <Route
                path={ESCALATIONS_ROUTES.__ROOT__}
                component={EscalationsRoutes}
              />

              <Route
                path={POSITIONS_ROUTES.__ROOT__}
                component={PositionsRoutes}
              />

              <Route path={SITES_ROUTES.__ROOT__} component={SitesRoutes} />

              <Route path={TASKS_ROUTES.__ROOT__} component={TasksRoutes} />

              <Route path={REPORTS_ROUTES.__ROOT__} component={ReportsRoutes} />

              <Route path={USERS_ROUTES.__ROOT__} component={UsersRoutes} />

              <Route
                path={EMAIL_PREFERENCES_ROUTES.__ROOT__}
                component={EmailPreferencesRoutes}
              />

              <Redirect to={ORGANIZATION_ROUTES.__ROOT__} />
            </Switch>
          </AppLayoutContainer>
        </BreadcrumbsContextProvider>
      </ContainerDimensionsContextProvider>
    </ErrorBoundary>
  )
}

export { AppRouter }
