import React from 'react'
import { Loading, TableGroup } from '@therms/atalaya'
import { loadCustomReportCompletions } from '@src/services/api-client'
import { useApiWithSiteId } from '@src/hooks/useApiWithSiteId'

export function CustomReportCompletionsTable() {
  const { data, isCalling } = useApiWithSiteId(loadCustomReportCompletions, {})

  if (isCalling) {
    return <Loading className="text-center m-6" />
  }

  return (
    <TableGroup
      data={data ?? []}
      keyProp="id"
      HeadRow={
        <TableGroup.HeadRow>
          <TableGroup.HeadCell>Date</TableGroup.HeadCell>
          <TableGroup.HeadCell>Name</TableGroup.HeadCell>
        </TableGroup.HeadRow>
      }
      Row={({ rowData }) => (
        <TableGroup.Row rowKey={rowData.id}>
          <TableGroup.Cell>{rowData.mts}</TableGroup.Cell>
          <TableGroup.Cell>{rowData.title}</TableGroup.Cell>
        </TableGroup.Row>
      )}
    />
  )
}
