import { Button, Password, Select, Text } from '@therms/atalaya'
import React, { useState } from 'react'
import { Alerts } from '../../../components/Alerts'
import { OrganizationGetOrganizationOkResponseBody, SuperuserGetAllOrgs, SuperuserGetOrgOwnerJwt } from '../../../services/api-client'

interface LoginProps {
    onComplete: (jwt: string) => void
    setLoading: (loading: boolean) => void
  }


function SuperuserLogin({ onComplete, setLoading }: LoginProps) {
    const [allOrganizations, setAllOrganizations] = useState<OrganizationGetOrganizationOkResponseBody[]>([])
    const [superuserUsername, setSuperuserUsername] = useState('')
    const [superuserPassword, setSuperuserPassword] = useState('')
    const noOrganization = {label:'None', value:''}
    const organizationsForList = [noOrganization, ...allOrganizations.map(org => ({label: org.name, value:org.id}))]
    const [targetOrganization, setTargetOrganization] = useState('')

    const handleSuperuserGetJWT = async () => {
        const organizationId = targetOrganization
        if (organizationId === '') return
        setLoading(true)
        try {
          const {
            data: { userId, sessionKey, token },
          } = await SuperuserGetOrgOwnerJwt({ superuserUsername, superuserPassword, organizationId })
    
          console.log('Login success', { userId, sessionKey, token })
          setSuperuserPassword('')
          setSuperuserUsername('')
          onComplete(token)
        } catch (err) {
          console.log('Login err', err)
          Alerts.error('Unable to authenticate try again')
        } finally {
          setLoading(false)
        }
      }

    const handleSuperuserGetOrgs = async () => {
        setLoading(true)
        try {
          const {
            data
          } = await SuperuserGetAllOrgs({ superuserUsername, superuserPassword })
          setAllOrganizations(data)
        } catch (err) {
          console.log('Login err', err)
          Alerts.error('Unable to authenticate try again')
        } finally {
          setLoading(false)
        }
      }
    return (<>
        <span />
        {(allOrganizations.length > 0) ? (
            <>
                <Select
                    label="Select Organization"
                    onChangeValue={setTargetOrganization}
                    options={organizationsForList}
                    subText="You will be logged in as this organization"
                    value={targetOrganization}
                />
                <Button onClick={handleSuperuserGetJWT} fullWidth variant="main">
                        Continue
                </Button>
            </>
        ) : (
            <>
                <Text placeholder='Username' onChangeValue={setSuperuserUsername} value={superuserUsername} />
                <Password placeholder='Password' onChangeValue={setSuperuserPassword} value={superuserPassword} />
                <Button onClick={handleSuperuserGetOrgs} fullWidth variant="main">
                    Superuser Login
                </Button>
            </>
        )}
        
      </>)
  }
  
  SuperuserLogin.defaultProps = {}
  
  export { SuperuserLogin }