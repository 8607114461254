import React from 'react'
import { Loading, TableGroup } from '@therms/atalaya'
import { loadCustomReports } from '@src/services/api-client'
import { useApi } from '@src/hooks/useApi'
import { useAuth } from '@src/modules/Auth/shared/state'

export function ReportTypesTable() {
  const { currentSite } = useAuth()
  const { data, isCalling } = useApi(loadCustomReports, currentSite.id)

  if (isCalling) {
    return <Loading className="text-center m-6" />
  }

  return (
    <TableGroup
      data={data ?? []}
      keyProp="id"
      HeadRow={
        <TableGroup.HeadRow>
          <TableGroup.HeadCell>Name</TableGroup.HeadCell>
        </TableGroup.HeadRow>
      }
      Row={({ rowData }) => (
        <TableGroup.Row rowKey={rowData.id}>
          <TableGroup.Cell>{rowData.title}</TableGroup.Cell>
        </TableGroup.Row>
      )}
    />
  )
}
