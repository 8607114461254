import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { REPORTS_ROUTES } from '../constants'
import { ReportsIndex } from '@src/modules/Reports/router/screens/Index'
import { Button } from '@therms/atalaya'
import { ErrorBoundary } from 'react-error-boundary'
import * as Sentry from '@sentry/react'

function ReportsRoutes() {
  return (
    <Switch>
      <Route
        render={() => (
          <ErrorBoundary
            onError={(error, componentStack) => {
              Sentry.withScope((scope) => {
                scope.setExtras(componentStack)
                Sentry.captureException(error)
              })
            }}
            fallbackRender={({ error, resetErrorBoundary }) => (
              <div>
                <p>Something went wrong:</p>
                <pre>{error.message}</pre>
                <Button onClick={resetErrorBoundary}>Try again</Button>
              </div>
            )}
          >
            {/*<TaskForm />*/}
          </ErrorBoundary>
        )}
        path={REPORTS_ROUTES.EDIT_REPORT}
      />
      <Route
        render={() => (
          <ErrorBoundary
            onError={(error, componentStack) => {
              Sentry.withScope((scope) => {
                scope.setExtras(componentStack)
                Sentry.captureException(error)
              })
            }}
            fallbackRender={({ error, resetErrorBoundary }) => (
              <div>
                <p>Something went wrong:</p>
                <pre>{error.message}</pre>
                <Button onClick={resetErrorBoundary}>Try again</Button>
              </div>
            )}
          >
            {/*<TaskForm />*/}
          </ErrorBoundary>
        )}
        path={REPORTS_ROUTES.NEW_REPORT}
      />
      <Route
        render={() => (
          <ErrorBoundary
            onError={(error, componentStack) => {
              Sentry.withScope((scope) => {
                scope.setExtras(componentStack)
                Sentry.captureException(error)
              })
            }}
            fallbackRender={({ error, resetErrorBoundary }) => (
              <div>
                <p>Something went wrong:</p>
                <pre>{error.message}</pre>
                <Button onClick={resetErrorBoundary}>Try again</Button>
              </div>
            )}
          >
            <ReportsIndex />
          </ErrorBoundary>
        )}
      />
    </Switch>
  )
}

export { ReportsRoutes }
